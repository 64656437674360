<template>
  <body>
    <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="img/favicon.ico" />
      <link rel="stylesheet" href="theme.css" type="text/css" />
      <title>Porto - Portal de Autonegociação</title>
    </head>

    <body>
      <div class="capa1" style="" v-if="this.largura > 500">
        <div v-bind:class="[this.largura > 500 ? 'container' : 'container']">
          <div v-bind:class="[this.largura > 500 ? 'row' : 'row']">
            
            <div class="pt-4 col-lg-5" v-if="this.largura > 500">
              <h1
                class="pl-0 text-white col-1 d-none d-lg-block mt-5 col-lg-12"
                style=""
              >
                Negocie suas dívidas para você ficar no azul!
              </h1>

            <!-- <img
              src="/img/banner-feirao.png"
              alt=""
              style="width: 100%; margin-left: 25px;"
            /> -->
          </div>
          <div class="col-lg-2">

            </div>
          <!-- <div class="col-lg-4"></div> -->
          <div
            class="col-lg-5 justify-content-center d-flex"
            style="margin-bottom: 100px;"
          >
            <div class="card" style="max-height: max-content; margin-left: 100px; max-width: 450px;">
              <div class="border-top-0 px-3 pt-3">
                <h5 class="text-body">Negociação de Dívidas</h5>
                <h6 class="text-muted mb-3">
                  Descubra as melhores condições para você quitar suas dívidas
                  com o Porto Bank.
                </h6>
                <form>
                  <div class="form-group mb-3">
                    <div class="form-group">
                      <input
                        type="text"
                        required
                        class="form-control form-control-sm"
                        placeholder="Seu CPF ou CNPJ"
                        id="accessDocInput"
                        label="Digite"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        v-model="form.accessdoc"
                        @change="changeAccessDocInput(form.accessdoc)"
                        @input="saveDocument"
                      />
                    </div>
                    <div class="form-group mb-3 position-relative">
                      <input
                        :type="isPasswordVisible ? 'text' : 'password'"
                        class="form-control form-control-sm"
                        placeholder="Código de acesso"
                        id="passwordInput"
                        v-model="form.promoCode"
                        :disabled="loading"
                        @change="changeAccessCodeInput()"
                      />
                      <PasswordToggleButton
                        :isPasswordVisible="isPasswordVisible"
                        @toggle-visibility="togglePasswordVisibility"
                      />
                    </div>
                    <div class="row">
                      <div
                        class="col-12 d-flex align-items-baseline justify-content-between"
                      >
                        <small class="form-text text-muted">
                          <a href="#" @click="getContacts(true)" class="mt-4">
                            Quero receber o código</a
                          >
                        </small>
                        <small class="form-text text-muted">
                          <a
                            href="#"
                            @click="getContacts(false)"
                            class="mt-4 text-right"
                            >Esqueci o código</a
                          >
                        </small>
                      </div>
                    </div>
                  </div>
                  <button
                    @click="login"
                    id="btn-negociar"
                    type="button"
                    v-if="!loading"
                    :disabled="!form.promoCode && !form.accessdoc"
                    class="btn mb-2 btn-primary p-3"
                    style="width: 100%; border-radius: 25px; font-size: 16px"
                  >
                    NEGOCIAR AGORA
                  </button>
                </form>
                <div class="mt-3">
                  <ProgressLoading v-bind:loading="loading" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div class="capa1" style="" v-if="this.largura < 500">
      <div class="container p-0 pr-3 pl-3" v-if="largura < 500">
        <!-- <img
          src="/img/banner-feirao.png"
          alt=""
          style="width: 100%;"
        /> -->

        <div
          v-if="largura < 500"
          
        >
          <div class="container">
            <div class="col-12" :style="largura < 500 ? '' : ''">
              <div class="card">
                <div class="border-top-0 px-3 pt-3">
                  <h5 class="text-body">Negociação de Dívidas</h5>
                  <h6 class="text-muted mb-3">
                    Descubra as melhores condições para você quitar suas dívidas
                    com o Porto Bank.
                  </h6>
                  <form>
                    <div class="form-group mb-3">
                      <div class="form-group">
                        <input
                          type="text"
                          required
                          class="form-control form-control-sm"
                          placeholder="Seu CPF ou CNPJ"
                          id="accessDocInput"
                          label="Digite"
                          v-mask="['###.###.###-##', '##.###.###/####-##']"
                          v-model="form.accessdoc"
                          @change="changeAccessDocInput(form.accessdoc)"
                          @input="saveDocument"
                        />
                      </div>
                      <div class="form-group mb-3 position-relative">
                        <input
                          :type="isPasswordVisible ? 'text' : 'password'"
                          class="form-control form-control-sm"
                          placeholder="Código de acesso"
                          id="passwordInput"
                          v-model="form.promoCode"
                          :disabled="loading"
                          @change="changeAccessCodeInput()"
                        />
                        <PasswordToggleButton
                          :isPasswordVisible="isPasswordVisible"
                          @toggle-visibility="togglePasswordVisibility"
                        />
                      </div>
                      <div class="row">
                        <div
                          class="col-12 d-flex align-items-baseline justify-content-between"
                        >
                          <small class="form-text text-muted">
                            <a
                              @click="getContacts(true)"
                              class="mt-4"
                              style="color: #0a0047; cursor: pointer"
                            >
                              Quero receber o código</a
                            >
                          </small>
                          <small class="form-text text-muted">
                            <a
                              @click="getContacts(false)"
                              class="mt-4 text-right"
                              style="color: #0a0047; cursor: pointer"
                              >Esqueci o código</a
                            >
                          </small>
                        </div>
                      </div>
                    </div>
                    <button
                      @click="login"
                      id="btn-negociar"
                      type="button"
                      v-if="!loading"
                      :disabled="!form.promoCode && !form.accessdoc"
                      class="btn mb-2 btn-primary p-3"
                      style="width: 100%; border-radius: 25px; font-size: 16px"
                    >
                      NEGOCIAR AGORA
                    </button>
                  </form>
                  <div class="mt-3">
                    <ProgressLoading v-bind:loading="loading" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div
        class="sec10 bg-light"
        :style="largura > 500 ? '' : ''"
      >
        <div class="container">
          <div class="row justify-content-center text-center mb-5">
            <div class="col-md-12 text-dark">
              <h2 class="py-4">
                Como fazer a negociação online do seu Cartão de Crédito Porto
                Bank
              </h2>
            </div>
          </div>
          <div
            class="row d-flex justify-content-center text-dark"
            :style="largura < 500 ? 'padding: 40px;' : ''"
          >
            <div class="col-6 col-lg-3 p-2 pl-3 pr-4" style="">
              <img class="img-fluid d-block mb-3" src="img/ic01.jpg" />
              <h5>Identifique-se fazendo o login.</h5>
            </div>
            <div class="col-6 col-lg-3 p-2 pr-4 pl-3" style="">
              <img class="img-fluid d-block mb-3" src="img/ic02.jpg" />
              <h5>Selecione a melhor opção para você.</h5>
            </div>
            <div class="col-6 col-lg-3 p-2 pr-4 pl-3" style="">
              <img class="img-fluid d-block mb-3" src="img/ic03.jpg" />
              <h5 class="" style="">Gere o boleto e faça o pagamento.</h5>
            </div>
            <div class="col-6 col-lg-3 p-2 pr-4 pl-3" style="">
              <img class="img-fluid d-block mb-3" src="img/ic04.jpg" />
              <h5 class="">Pronto! Tudo certo com a sua negociação.</h5>
            </div>
          </div>
        </div>
      </div>
      <section class="bg-light py-3">
        <div class="container py-5">
          <div class="row">
            <div class="col-11 mx-auto">
              <h4>
                Tire suas dúvidas sobre a Negociação de Dívidas
                <hr style="border-color: #fff" />
              </h4>
              <div class="accordion" id="faq">
                <div class="card">
                  <div class="card-header p-2" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        class="btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse1"
                        aria-expanded="true"
                        aria-controls="collapse1"
                        style="font-size: 16px"
                      >
                        O que é a Negociação de Dívidas?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse1"
                    class="collapse"
                    aria-labelledby="heading1"
                  >
                    <div class="card-body px-4">
                      É uma forma de encontrar o caminho mais fácil para
                      negociar as suas dívidas em nossos produtos financeiros.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading2">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        aria-expanded="false"
                        data-target="#collapse2"
                        aria-controls="collapse2"
                        data-toggle="collapse"
                        style="font-size: 16px"
                      >
                        De que forma posso pagar a negociação do cartão de
                        crédito?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse2"
                    class="collapse"
                    aria-labelledby="heading2"
                  >
                    <div class="card-body px-4">
                      Você pode realizar o pagamento por meio de boleto bancário
                      no portal ou pela central de atendimento.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading3">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                        style="font-size: 16px"
                      >
                        Quanto tempo leva, após o pagamento, para sair das
                        restrições de crédito?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse3"
                    class="collapse"
                    aria-labelledby="heading3"
                  >
                    <div class="card-body px-4">
                      Após o pagamento da negociação, são até 5 dias úteis para
                      que seu nome não esteja mais restrito.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading4">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                        style="font-size: 16px"
                      >
                        Como posso emitir uma segunda via de boleto de
                        negociação?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse4"
                    class="collapse"
                    aria-labelledby="heading4"
                  >
                    <div class="card-body px-4">
                      Para cartão de crédito, acesse o portal e emita a segunda
                      via de boleto. Para os demais produtos, entre em contato
                      com a central de atendimento disponível e solicite a
                      segunda via.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading4">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                        style="font-size: 16px"
                      >
                        Qual é o prazo para pagamento do boleto da negociação?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse5"
                    class="collapse"
                    aria-labelledby="heading5"
                  >
                    <div class="card-body px-4">
                      Você tem até 15 dias para pagar o boleto da negociação de
                      dívida.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading6">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                        style="font-size: 16px"
                      >
                        Tenho condições especiais para negociar as minhas
                        dívidas?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse6"
                    class="collapse"
                    aria-labelledby="heading6"
                    style=""
                  >
                    <div class="card-body px-4">
                      Para conferir as condições de negociação do cartão de
                      crédito, acesse a nossa página. Para os demais produtos,
                      entre em contato com as centrais de atendimento
                      disponíveis e consulte se há uma oferta personalizada para
                      você.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading7">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                        style="font-size: 16px"
                      >
                        O que é o Programa Desenrola Brasil?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse7"
                    class="collapse"
                    aria-labelledby="heading7"
                    style=""
                  >
                    <div class="card-body px-4">
                      O Desenrola Brasil é uma iniciativa do Governo Federal
                      para ajudar na negociação de dívidas das pessoas com CPF
                      negativado. As dívidas participantes serão aquelas
                      negativadas pelos credores junto aos órgãos de crédito
                      entre 01/01/2019 e 31/12/2022. Para consultar sua
                      situação, entre em contato com o Serasa, Boa Vista e Quod.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading8">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8"
                        style="font-size: 16px"
                      >
                        Quem está elegível ao Desenrola Brasil?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse8"
                    class="collapse"
                    aria-labelledby="heading8"
                    style=""
                  >
                    <div class="card-body px-4">
                      O Desenrola Brasil contempla duas faixas de público:
                      <br /><br />

                      Faixa 1: pessoas físicas com renda mensal de até 2
                      salários-mínimos ou que estejam inscritas no Cadastro
                      único para Programas Sociais do Governo Federal (Cadúnico)
                      que possuam dívidas negativadas com bancos e/ou
                      prestadoras de serviço de até R$ 5 mil. <br /><br />

                      Faixa 2: pessoas físicas com renda mensal de até R$ 20 mil
                      que possuem dívidas com bancos e inscritas em cadastros de
                      inadimplentes até 31/12/2022. <br /><br />

                      Para as duas fases, o prazo final de adesão é até
                      31/12/2023.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="heading9">
                    <h5 class="mb-0">
                      <button
                        class="btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapse9"
                        style="font-size: 16px"
                      >
                        Como negociar dívidas no Desenrola Brasil?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse9"
                    class="collapse"
                    aria-labelledby="heading9"
                    style=""
                  >
                    <div class="card-body px-4">
                      Clientes Faixa 1: a partir de setembro/23, em data a ser
                      informada, poderão acessar o portal oficial do Desenrola
                      Brasil, via site gov.br, que está em construção.
                      <br /><br />

                      Clientes Faixa 2: desde 17/07/2023, podem negociar as
                      dívidas diretamente nos canais de atendimento Porto Bank.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </body>
  </body>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, minLength, helpers } from "vuelidate/lib/validators";
import { validaCpfCnpj, ActionGetIPAddress } from "@/utils/helpers";
import ProgressLoading from "./ProgressLoading.vue";
import { encryptTextWithKey, decryptHashWithKey } from "@/utils/crypto";
import { EventBus } from "@/utils/EventBus";
import { initializeGlobals } from "@/global";
import PasswordToggleButton from "./PasswordToggleButton";

const regexaccessdoc = helpers.regex(
  "regexaccessdoc",
  /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/
);

export default {
  name: "Login",
  components: {
    ProgressLoading,
    PasswordToggleButton,
  },
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    form: {
      accessdoc: { required, minLength: minLength(14), regexaccessdoc },
    },
  },
  data: () => ({
    form: {
      accessdoc: null,
      promoCode: null,
      termsAcepted: false,
    },
    error: {
      alert: false,
      status: null,
      message: null,
    },
    errors: false,
    loading: false,
    promoCode: null,
    sendCode: false,
    EmailOrPhone: "email",
    email: true,
    sms: false,
    code: false,
    emailDebtor: null,
    smsDebtor: null,
    infoDebtor: null,
    user: null,
    largura: null,
    customData: {},
    pageLocation: null,
    url: null,
    protocol: null,
    path: null,
    host: null,
    ipUser: null,
    isPasswordVisible: false,
  }),
  computed: {
    classMessage: function () {
      return {
        "text-danger": this.error.status == "ERROR",
        "text-muted": !this.error.status == "ERROR",
      };
    },
    accessdocErrors() {
      const errors = [];
      if (!this.$v.form.accessdoc.$dirty) return errors;
      !this.$v.form.accessdoc.minLength &&
        errors.push("Digite um CPF/CNPJ válido");
      !this.$v.form.accessdoc.required &&
        errors.push("CPF/CNPJ é obrigatório!");

      if (this.$v.form.accessdoc.length === 14) {
        !this.$v.form.accessdoc.regexaccessdoc &&
          errors.push("CPF não é válido");
      } else if (this.$v.form.accessdoc.length === 18) {
        !this.$v.form.accessdoc.regexaccessdoc &&
          errors.push("CNPJ não é válido");
      }

      return errors;
    },
  },
  methods: {
    ...mapActions("auth", [
      "ActionDoLogin",
      "ActionGetContactsByDocNumber",
      "ActionSetCurrentForm",
      "ActionSetContacts",
      "ActionGetLinkAccessAsDebtor",
    ]),

    invalidateForm() {
      this.errors = true;
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },

    async getContacts(isReceberCodigo) {
      //eslint-disable-next-line
      // debugger;
      var route = null;
      if (isReceberCodigo) {
        route = "quero-receber-o-codigo";
        const pageName = this.$options.name;
        const eventCategory = `portal-de-negociacao:porto:${pageName}`;
        const eventAction = "click:link:quero-receber-o-codigo";
        const eventData = {
          event: "select_content",
          ev_category: eventCategory,
          ev_action: eventAction,
          ev_label: "",
        };

        localStorage.setItem("currentPageName", "quero-receber-o-codigo");

        window.dataLayer.push({ event: "select_content", ...eventData });
      } else {
        route = "receber-codigo";
        const pageName = this.$options.name;
        const eventCategory = `portal-de-negociacao:porto:${pageName}`;
        const eventAction = "click:link:esqueci-o-codigo";
        const eventData = {
          event: "select_content",
          ev_category: eventCategory,
          ev_action: eventAction,
          ev_label: "",
        };

        localStorage.setItem("currentPageName", "receber-codigo");

        window.dataLayer.push({ event: "select_content", ...eventData });
      }

      this.loading = true;
      this.accessdocErrors.pop();
      this.$v.$touch();

      if (!this.form.accessdoc || this.form.accessdoc === "") {
        this.loading = false;
        const pageName = this.$options.name;
        const eventCategory = `portal-de-negociacao:porto:${pageName}`;
        const eventAction = "login:alert";
        const eventLabel = "Informe um CPF/CNPJ";
        const eventCode = 401;
        const eventErrorService = "";
        const eventAlertService = "Informe um CPF/CNPJ";
        const eventAlert = "login";
        const eventAlertType = "bloqueio";
        const eventData = {
          event: "alert",
          ev_category: eventCategory,
          ev_action: eventAction,
          ev_label: eventLabel,
          alert_code: eventCode,
          error_service: eventErrorService,
          alert_service_message: eventAlertService,
          alert_event: eventAlert,
          alert_type: eventAlertType,
        };

        window.dataLayer.push({ event: "alert", ...eventData });
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message: "Informe um CPF/CNPJ",
        });
        this.loading = false;
        return;
      }

      var isDocValid = validaCpfCnpj(this.form.accessdoc);

      if (this.$v.$invalid || !isDocValid) {
        this.loading = false;
        if (!isDocValid) {
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = "login:alert";
          const eventLabel = "Informe um CPF/CNPJ válido";
          const eventCode = 401;
          const eventErrorService = "";
          const eventAlertService = "Informe um CPF/CNPJ válido";
          const eventAlert = "login";
          const eventAlertType = "bloqueio";
          const eventData = {
            event: "alert",
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: eventLabel,
            alert_code: eventCode,
            error_service: eventErrorService,
            alert_service_message: eventAlertService,
            alert_event: eventAlert,
            alert_type: eventAlertType,
          };

          window.dataLayer.push({ event: "alert", ...eventData });

          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message: "Informe um CPF/CNPJ válido",
          });
          return;
        }
      } else {
        let accessdoc = this.form.accessdoc.replace(/\D+/g, "");

        try {
          let response = await this.ActionGetContactsByDocNumber({
            DocNumber: accessdoc,
            OS: navigator.platform,
            IP: this.ipUser ? this.ipUser : "",
          });

          if (!response.data.code || response.data.code !== 200) {
            let url = "https://portoseguro.com.br/negocie";
            this.flashMessage.show({
              time: 0,
              status: "error",
              position: "top right",
              title: "Ocorreu um erro",
              message: `Ops... tivemos um problema. Ligue para: SP: 4004-3600 Demais regiões: 0800 727 7477.`,
              html: `<div class="m-2"><p>Tivemos um problema. Encontre outros canais de negociação em:  <a href="${url}" target="_blank">${url}</a></p></div>`,
            });
            this.loading = false;
            return;
          }

          if (response.data.results.SourceInfo == "EXTERNO") {
            this.ActionSetContacts({
              DocNumber: response.data.results.DocNumber,
              Emails: response.data.results.Emails,
              PhoneNumbers: response.data.results.MobileNumbers,
              ListEmailsWithMask: response.data.results.ListEmailsWithMask,
              ListPhonesWithMask: response.data.results.ListPhonesWithMask,
              SourceInfo: "API_EXTERNA",
            });
            this.contacts = response.data.results;
            this.loading = false;

            this.buildAndSetUserData(
              accessdoc,
              response.data.results.DebtorID,
              this.protocol + "//" + this.host + `/dashboard/${route}`,
              route
            );
            this.emitUpdatePageViewEvent(`/dashboard/${route}`);
            this.updateCustomData();
            this.ActionSetCurrentForm("form-send-code");
          } else {
            this.loading = false;
            this.contacts = response.data.results;
            this.ActionSetContacts(response.data.results);

            this.buildAndSetUserData(
              accessdoc,
              response.data.results.DebtorID,
              this.protocol + "//" + this.host + `/dashboard/${route}`,
              route
            );
            this.emitUpdatePageViewEvent(`/dashboard/${route}`);
            this.updateCustomData();
            this.ActionSetCurrentForm("form-send-code");
          }
        } catch (error) {
          this.accessdocErrors.push("Não foi possível fazer login!");
          this.error.status = "ERROR";
          this.loading = false;
        }
      }
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      const docEncrypted = this.encryptData(doc);
      const userData = {
        cpfCnpj: docEncrypted,
        id: id,
        page_location: pageLocation,
        pageName: pageName,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
    },

    async login() {
      this.loading = true;

      this.$v.$touch();
      var isDocValid = validaCpfCnpj(this.form.accessdoc);

      if (this.$v.$invalid || !isDocValid) {
        this.loading = false;
        if (!isDocValid) {
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = "login:alert";
          const eventLabel = "Informe um CPF/CNPJ válido";
          const eventCode = 401;
          const eventErrorService = "";
          const eventAlertService = "Informe um CPF/CNPJ válido";
          const eventAlert = "login";
          const eventAlertType = "bloqueio";
          const eventData = {
            event: "alert",
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: eventLabel,
            alert_code: eventCode,
            error_service: eventErrorService,
            alert_service_message: eventAlertService,
            alert_event: eventAlert,
            alert_type: eventAlertType,
          };

          window.dataLayer.push({ event: "alert", ...eventData });

          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message: "Informe um CPF/CNPJ válido",
          });
          return;
        }
      }

      if (!this.form.promoCode || this.form.promoCode === "") {
        this.loading = false;
        const pageName = this.$options.name;
        const eventCategory = `portal-de-negociacao:porto:${pageName}`;
        const eventAction = "login:alert";
        const eventLabel = "Informe o código de acesso";
        const eventCode = 401;
        const eventErrorService = "";
        const eventAlertService = "Informe o código de acesso";
        const eventAlert = "login";
        const eventAlertType = "bloqueio";
        const eventData = {
          event: "alert",
          ev_category: eventCategory,
          ev_action: eventAction,
          ev_label: eventLabel,
          alert_code: eventCode,
          error_service: eventErrorService,
          alert_service_message: eventAlertService,
          alert_event: eventAlert,
          alert_type: eventAlertType,
        };

        window.dataLayer.push({ event: "alert", ...eventData });
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message: "Informe o código de acesso",
        });
        return;
      } else {
        let accessdoc = this.form.accessdoc.replace(/\D+/g, "");

        let response = await this.ActionGetContactsByDocNumber({
          DocNumber: accessdoc,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
        });

        if (!response.data.code || response.data.code !== 200) {
          let url = "https://portoseguro.com.br/negocie";
          this.flashMessage.show({
            time: 0,
            status: "error",
            position: "top right",
            title: "Erro",
            message:
              "Ops... tivemos um problema. Ligue para: SP: 4004-3600 Demais regiões: 0800 727 7477.",
            html: `<div class="m-2"><p>Tivemos um problema. Encontre outros canais de negociação em:  <a href="${url}" target="_blank">${url}</a></p></div>`,
          });

          this.loading = false;
          return;
        }

        if (response.data.results.SourceInfo == "EXTERNO") {
          this.ActionSetContacts({
            DocNumber: response.data.results.DocNumber,
            Emails: response.data.results.Emails,
            PhoneNumbers: response.data.results.MobileNumbers,
            ListEmailsWithMask: response.data.results.ListEmailsWithMask,
            ListPhonesWithMask: response.data.results.ListPhonesWithMask,
            SourceInfo: "API_EXTERNA",
          });
          this.contacts = response.data.results;
          this.loading = false;
        } else {
          let debtorId = response.data.results.DebtorID;
          let promoCode = this.form.promoCode;

          try {
            await this.ActionDoLogin({
              DebtorID: debtorId,
              PromoCode: promoCode,
              device_name: process.env.VUE_APP_API_DEVICE,
              IP: this.ipUser ? this.ipUser : "",
              OS: navigator.platform,
            });
            this.loading = false;

            this.buildAndSetUserData(
              accessdoc,
              debtorId,
              this.protocol + "//" + this.host + "/dashboard",
              "Dashboard"
            );
            await this.eventCallbackSuccessLoginGTM(accessdoc);
            this.updateCustomData();

            await this.$router.push({ name: "dashboard" });

            localStorage.setItem(
              "authUser",
              JSON.stringify(this.$store.state.auth.user)
            );

            window.location.reload();
          } catch (error) {
            this.loading = false;
            const pageName = this.$options.name;
            const eventCategory = `portal-de-negociacao:porto:${pageName}`;
            const eventAction = "login:alert";
            const eventLabel = error.data.message;
            const eventCode = error.status;
            const eventErrorService = "";
            const eventAlertService = error.data.message;
            const eventAlert = "login";
            const eventAlertType = "bloqueio";
            const eventData = {
              event: "alert",
              ev_category: eventCategory,
              ev_action: eventAction,
              ev_label: eventLabel,
              alert_code: eventCode,
              error_service: eventErrorService,
              alert_service_message: eventAlertService,
              alert_event: eventAlert,
              alert_type: eventAlertType,
            };

            window.dataLayer.push({ event: "alert", ...eventData });

            this.flashMessage.show({
              timeout: 300,
              status: "error",
              position: "top right",
              title: "Erro",
              message: "CPF/CNPJ ou código de acesso incorretos",
            });
            this.loading = false;
            return;
          }
        }
      }
    },

    saveDocument() {
      window.localStorage.setItem("doc", this.form.accessdoc);
    },

    sendCodeForm() {
      this.ActionSetCurrentForm("form-send-code");
    },
    clear() {
      this.$v.$reset();
      this.form.accessdoc = "";
      this.form.termos = false;
    },

    async changeAccessDocInput() {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "preencher:input:seu-cpf-ou-cnpj";
      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    changeAccessCodeInput() {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "preencher:input:codigo-de-acesso";
      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    emitUpdatePageViewEvent(pageName) {
      EventBus.$emit("updatePageView", pageName);
    },

    encryptData(doc) {
      const encryptedData = encryptTextWithKey(doc, "chave");
      return encryptedData;
    },

    decryptData(value, key) {
      const decryptedData = decryptHashWithKey(value, key);
      return decryptedData.trim();
    },

    formatarCnpjCpf(cnpjCpf) {
      const numeros = cnpjCpf.replace(/\D/g, "");

      if (numeros.length === 11) {
        return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else if (numeros.length === 14) {
        return numeros.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      } else {
        return cnpjCpf;
      }
    },

    eventCallbackSuccessLoginGTM(accessdoc) {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "login:sucesso";
      const eventLabel = accessdoc.length <= 11 ? "pf" : "pj";
      const eventMethod = "senha";
      const eventData = {
        event: "login",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: eventLabel,
        method: eventMethod,
      };

      window.dataLayer.push({ event: "login", ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    },

    async getIPAddressUser() {
      let maxAttempts = 10;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          this.ipUser = await ActionGetIPAddress();
          if (this.ipUser !== null) {
            localStorage.setItem("ipUser", this.ipUser);
          }
          break;
        } catch (error) {
          console.log("Erro ao obter o endereço IP. Tentando novamente...");
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      if (attempts === maxAttempts) {
        console.log(
          "Número máximo de tentativas atingido. Não foi possível obter o endereço IP."
        );
      }
    },
  },

  async created() {
    await this.getIPAddressUser();

    if (this.$route.query.SApp) {
      localStorage.setItem(
        "encryptedData",
        this.$route.query.SApp.replaceAll(" ", "+")
      );
      localStorage.removeItem("infoEncrypted");
      this.ActionSetCurrentForm("fake-loading");
    }

    if (this.$route.query.cod) {
      var infoEncrypted = this.$route.query.cod;
      if (infoEncrypted !== null) {
        localStorage.setItem("infoEncrypted", infoEncrypted);
        localStorage.removeItem("encryptedData");
        this.ActionSetCurrentForm("fake-loading");
      } else {
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message: "Ocorreu um erro.",
        });
        return;
      }
    }

    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + "//" + this.host + this.path;
    this.buildAndSetUserData(null, null, this.pageLocation, "Login");
    this.$emit("updatePageView", this.pageLocation);
    this.updateCustomData();

    this.largura = window.screen.width;
  },
};
</script>
<style>
.mx-64 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}
.nowrap {
  flex-wrap: nowrap !important;
}

@media (max-width: 768px) {
  div[style*="backgroundImage"] {
    height: auto; /* Permite que a altura seja ajustada automaticamente */
  }
}
</style>